<template>
  <div class="page-container">
    <div class="page-top">
      <search-form :searchForm="tableConfig.searchForm" :showCollapse="true" :visibleSearchProps="visibleSearchProps"
        @handleQuery="handleQuery" :buttons="buttonConfig" @handleButtonClick="callMethod"></search-form>
    </div>
    <div class="page-table">
      <grid-table ref="gridTable" :tableConfig="tableConfig" @selectionChange="selectionChange"
        @editRow="handleShowDialog">
      </grid-table>
    </div>
    <div class="page-dialog">
      <edit-dialog ref="editDialog" v-if="dialogVisible" :formConfig="editFormConfig"
        @handleQuery="handleQuery"></edit-dialog>
      <trade-add ref="tradeAdd" v-if="tradeAddVisible" :formConfig="editFormConfig"
        @handleQuery="handleQuery"></trade-add>
      <trade-log ref="tradeLog" v-if="tradeLogVisible" :formConfig="editFormConfig"
        @handleQuery="handleQuery"></trade-log>
    </div>
    <div class="page-dialog">
      <download-order ref="downloadOrder" v-if="dialogVisible" @handleQuery="handleQuery"></download-order>
    </div>
  </div>
</template>

<script>
import { submit, exportFile } from "@/api/base";
import TradeLog from "./components/TradeLog.vue";
import TradeAdd from "./components/TradeAdd.vue";
import DownloadOrder from "./components/DownloadOrder.vue";

export default {
  name: "tradeIndex",
  components: {
    TradeLog,
    TradeAdd,
    DownloadOrder,
  },
  data() {
    return {
      buttonConfig: [...(this.$route.meta.pageConfig.buttons || [])],
      tableConfig: { ...(this.$route.meta.pageConfig.table || {}) },
      selectRows: [],
      dialogVisible: false,
      tradeAddVisible: false,
      tradeLogVisible: false,
      editFormConfig: {},
      visibleSearchProps: [
        "FilterTypeTid",
        "OrderNo",
        "OrderNoType",
        "Mobile",
        "MobileType",
        "BuyerName",
        "BuyerNick",
        "LCompanyID",
        "LogisticsNo",
        "SellFlag",
        "IsCanMerge",
      ],
    };
  },
  mounted() {
    this.$nextTick(async () => {
      if (this.tableConfig.searchForm.url) {
        const { data } = await submit(this.tableConfig.searchForm.url);
        const { items } = this.getFormRules(
          this.tableConfig.searchForm.items,
          data.InitData
        );
        this.$set(this.tableConfig.searchForm, "items", items);
      }
      this.handleQuery();
    });
  },
  methods: {
    handleQuery() {
      this.$refs.gridTable.fetchData();
    },
    selectionChange(selection) {
      this.selectRows = selection;
    },
    callMethod(button) {
      let methods = this.$options.methods;
      const _this = this;
      methods[button.method](_this, button);
    },
    handleSearch(_this, button) {
      _this.handleQuery();
    },
    handleShowDialog(_this, button) {
      if (
        button.isNeedSelectRow &&
        (!_this.selectRows || _this.selectRows.length == 0)
      ) {
        _this.$baseMessage("请选择数据", "error");
        return false;
      }
      if (
        button.isNeedSelectRow &&
        !button.isMultiSelectRow &&
        _this.selectRows.length > 1
      ) {
        _this.$baseMessage("只能选择一行", "error");
        return false;
      }
      _this.dialogVisible = true;
      _this.$nextTick(() => {
        _this.editFormConfig = { ...button.bindForm };
        _this.$refs.editDialog.init(
          button.label,
          _this.getTableParam(
            _this.selectRows,
            button.isNeedRowKey,
            button.isMultiSelectRow,
            _this.tableConfig.rowKey,
            button.paramJson
          )
        );
      });
    },
    handleToExcute(_this, button) {
      if (
        button.isNeedSelectRow &&
        (!_this.selectRows || _this.selectRows.length == 0)
      ) {
        _this.$baseMessage(`请选择要${button.label}的数据`, "error");
        return false;
      }
      if (
        button.isNeedSelectRow &&
        !button.isMultiSelectRow &&
        _this.selectRows.length > 1
      ) {
        _this.$baseMessage("只能选择一行", "error");
        return false;
      }
      if (button.isNeedConfirm) {
        _this.$baseConfirm(`确定选中项要${button.label}吗？`, null, () => {
          this.handleExecute(_this, button);
        });
      } else {
        this.handleExecute(_this, button);
      }
    },
    sendToSupplier(_this, button) {
      if (
        button.isNeedSelectRow &&
        (!_this.selectRows || _this.selectRows.length == 0)
      ) {
        _this.$baseMessage(`请选择要${button.label}的数据`, "error");
        return false;
      }
      if (
        button.isNeedSelectRow &&
        !button.isMultiSelectRow &&
        _this.selectRows.length > 1
      ) {
        _this.$baseMessage("只能选择一行", "error");
        return false;
      }
      if (button.isNeedConfirm) {
        _this.$baseConfirm(`确定选中项要${button.label}吗？`, null, () => {
          this.sendSupplier(_this, button);
        });
      } else {
        this.sendSupplier(_this, button);
      }
    },
    async sendSupplier(_this, button) {
      const { data, msg } = await submit(
        button.url,
        _this.getTableParam(
          _this.selectRows,
          button.isNeedRowKey,
          button.isMultiSelectRow,
          _this.tableConfig.rowKey,
          button.paramJson
        )
      );
      if (msg) {
        _this.$baseMessageBox(`${msg}`, `${button.label}提示`);
      } else {
        _this.$baseMessage(`${button.label}成功`, "success");
      }
      if (data) {
        window.open(data, "_blank");
      }
      _this.handleQuery();
    },
    async handleExecute(_this, button) {
      const { msg } = await submit(
        button.url,
        _this.getTableParam(
          _this.selectRows,
          button.isNeedRowKey,
          button.isMultiSelectRow,
          _this.tableConfig.rowKey,
          button.paramJson
        )
      );
      if (msg) {
        _this.$baseMessageBox(
          `${button.label}成功，${msg}`,
          `${button.label}提示`
        );
      } else {
        _this.$baseMessage(`${button.label}成功`, "success");
      }
      _this.handleQuery();
    },
    async handleDownloadImportTemplate(_this, button) {
      const { data } = await submit(button.url);
      var a = document.createElement("a");
      a.href = data.Url;
      a.download = data.FileName;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    async handleExportExcel(_this, button) {
      let param = {};
      if (
        button.isNeedSelectRow &&
        (!_this.selectRows || _this.selectRows.length == 0)
      ) {
        _this.$baseMessage(`请选择要${button.label}的数据`, "error");
        return false;
      }
      if (
        button.isNeedSelectRow &&
        !button.isMultiSelectRow &&
        _this.selectRows.length > 1
      ) {
        _this.$baseMessage("只能选择一行", "error");
        return false;
      }
      if (!button.isMultiSelectRow) {
        param[_this.tableConfig.rowKey] =
          _this.selectRows[0][_this.tableConfig.rowKey];
      } else {
        param[_this.tableConfig.rowKey + "s"] = _this.selectRows
          .map((row) => row[_this.tableConfig.rowKey])
          .join();
      }
      const { data } = await exportFile(button.url, param);
      const blob = new Blob([data.Blob], { type: "application/vnd.ms-excel" });
      var a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = data.FileName;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    add(_this, button) {
      _this.tradeAddVisible = true;
      _this.$nextTick(() => {
        _this.editFormConfig = { ...button.bindForm };
        _this.$refs.tradeAdd.init();
      });
    },
    showLog(_this) {
      if (!_this.selectRows || _this.selectRows.length == 0) {
        _this.$baseMessage("请选择数据", "error");
        return false;
      }
      if (_this.selectRows.length > 1) {
        _this.$baseMessage("只能选择一行", "error");
        return false;
      }
      _this.tradeLogVisible = true;
      _this.$nextTick(() => {
        _this.$refs.tradeLog.init(_this.selectRows[0].TradeID);
      });
    },
    downloadOrder(_this, button) {
      _this.dialogVisible = true;
      _this.$nextTick(() => {
        _this.$refs.downloadOrder.init();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-top {
  padding: 6px 14px 0 14px;
}
</style>
